<template>
  <app-list-view
    server-side
    app="propertyAndAgreement"
    model="masterpropertyandagreementheader"
    api-url="property-and-agreement/master-property-and-agreement-header/"
    :title="$t('menu.masterPropertyAndAgreementHeader')"
    :createTo="{ name: 'masterPropertyAndAgreementHeaderCreate' }"
    :editTo="{ name: 'masterPropertyAndAgreementHeaderEdit' }"
    :headers="headers"
  >
  </app-list-view>
</template>

<script>
import AppListView from '@components/AppListView'

export default {
  name: 'propertyAndAgreementList',
  components: {
    AppListView
  },
  computed: {
    headers() {
      return [
        {
          text: this.$t('fields.tabName'),
          value: 'name'
        },
        {
          text: this.$t('fields.thisTemplateIsNotAvailableFor'),
          value: 'masterPropertyAndAgreementDetail_id',
          chips: {
            color: 'secondary',
            icon: 'mdi-account-circle-outline',
            value: 'customer_company_id'
          },
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('fields.active'),
          value: 'active',
          check: true,
          groupable: false,
          hideFilter: true
        },
        {
          text: this.$t('label.action'),
          value: 'actions',
          sortable: false,
          groupable: false,
          hideFilter: true
        }
      ]
    }
  }
}
</script>
